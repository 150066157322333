@use 'sass:map';@import "@/src/assets/styles/global.scss";
// Normalize, Reset & Fonts
@import 'normalize.css';
@import 'reset-css/reset.css';
@import 'fonts.scss';

// General plugins needs to be imported here since nextjs has a problem ordering it during builds
// More info: https://github.com/vercel/next.js/blob/7966eaf0f210996abb34d2f02551b3ce28bdfac5/packages/next/src/build/webpack/config/blocks/css/index.ts#L603-L614
// Discussion: https://github.com/vercel/next.js/issues/13092
@import 'simplebar-react/dist/simplebar.min.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@import '@ag-grid-community/styles/ag-grid.css';

// Hack to fix an issue on global-error component because nextjs doesn't load external css
@import '@kelvininc/shared-ui/src/components/ErrorFallback/styles.module.scss';

body {
	background-color: $background-color;
	color: $text-color;
	@include kv-font-span-regular;
	@include include-colors-variables;

	* {
		box-sizing: border-box;
		@include kelvin-night-scrollbar;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

a,
a:visited,
a:hover,
a:active {
	text-decoration: inherit;
	color: inherit;
}

kv-multi-select-dropdown,
kv-single-select-dropdown {
	--dropdown-max-height: #{$dropdown-max-height};

	@media only screen and (height <= 960px) {
		--dropdown-max-height: 250px;
	}
}

.react-joyride__spotlight {
	@include rotate-border-animation;
}
