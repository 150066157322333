.Container {
	--illustration-bottom-margin: #{$spacing-6x};
	--title-bottom-margin: #{$spacing};
	--description-bottom-margin: #{$spacing-6x};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&Fullscreen {
		height: 100vh;
		width: 100%;
	}

	.Illustration {
		margin-bottom: var(--illustration-bottom-margin);

		kv-illustration {
			--illustration-width: 100px;
			--illustration-height: 86px;
		}
	}

	.Title {
		margin-bottom: var(--title-bottom-margin);
		color: kv-color('neutral-2');
		@include kv-font-h3-semibold;
	}

	.Description {
		margin-bottom: var(--description-bottom-margin);
		color: kv-color('neutral-4');
		@include kv-font-paragraph-regular;
	}
}
