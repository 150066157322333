@use 'sass:map';@import "@/src/assets/styles/global.scss";
.RouteLeaveConfirmationModal::part(topbar) {
	border-bottom: none;
}

.RouteLeaveConfirmationModal.RouteLeaveConfirmationModal {
	--modal-z-index: 21;
	--modal-width: 480px;
	--modal-height: 268px;
}

.RouteLeaveConfirmationModalContent {
	display: flex;
	flex-direction: column;
	row-gap: $spacing-4x;
	padding-top: $spacing-2x;
	padding-bottom: $spacing-2x;
	height: 100%;

	.Title {
		@include kv-font-h3-semibold;

		color: kv-color('neutral-0');
	}

	.Description {
		@include kv-font-paragraph-regular;

		color: kv-color('neutral-4');
	}
}
